import React from 'react'
import {Link} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CoolHeader, {SectionHeader} from '../components/Elements/CoolHeader'

const About = () => (
  <Layout>
    <SEO customTitle="About Pomme Journal" />
    <CoolHeader>A Modern Journal</CoolHeader>
    <p>
      We’re trying to shake things up a bit. Our printed literary journal will
      be available for purchase on Amazon, fulfilled by Amazon’s Kindle Direct
      Publishing. We’ll be leveraging Amazon’s wide reach, speedy Prime delivery
      and excellent customer service to serve your voice to readers everywhere.
      Pomme Journal will also be available in digital format via Amazon Kindle
      and in audio format on Audible. In 2019, we believe this flexible approach
      will mean maximum readership for your work.
    </p>
    <CoolHeader>The Journal</CoolHeader>
    <p>
      There is something about a printed book that just cannot be replaced by a
      screen. It’s a coffee table ornament, a friend’s birthday gift or a
      one-of-many item on your growing bookshelf. It smells good, feels good and
      it doesn’t get interrupted by pop-up-ads. Pomme Journal is a full-color
      8.25x8.25” book that you will be proud of.
    </p>
    <CoolHeader>Journal Matriarch</CoolHeader>
    <p>
      Happy wife and mama bear to three ornery cubs, Alicia is a busy lady. She
      earned a BFA in Visual Communication at the University of Kansas. Though
      most of her waking hours are spent wiping up spilled milk, singing the
      ABCs or reading a story for the hundredth time, she manages to find a
      little time to read, write and design. Dedicated to a successful literary
      journal, this multi-tasking master is ready to connect author voices to
      audience ears.
    </p>
    <p>
      We’re always looking to expand our quirky, wonderful cohort of volunteer
      readers. If you’re interested, please drop us a line at
      pommejournalcomm@gmail.com
    </p>
    <CoolHeader>The ongoing call for artists</CoolHeader>
    <p>
      We firmly agree with Alice that it is hard to pay attention to a book with
      no pictures in it. We’re always seeking artwork to accompany our lovely
      stories. Interested artists can send an email to
      pommejournalcomm@gmail.com for more information!
    </p>
    <CoolHeader>We want Pomme Journal to succeed!</CoolHeader>
    <p>
      Our goal is to keep the journal affordable for family, friends, paupers
      and princes. We have a vision for Pomme’s future, including paid
      contributors, paid readers, special editions, scholarships and lots more!
      Those dreams can be made reality if we get enough funds secured from
      journal and merchandise sales. If you’d like to help us along the way,
      we’ll humbly accept a donation below. If you happen to be a larger
      organization looking to partner with us, please contact us at
      pommejournalcomm@gmail.com.
    </p>
  </Layout>
)

export default About
